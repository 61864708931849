import React from 'react';
import { UserCard } from '../../components';

import css from './ListingPage.module.css';

const SectionAuthorMaybe = props => {
  const {
    author,
    currentUser,
    history,
    location,
    contactInfo,
    fetchContactInfoError,
    openContactModal,
  } = props;

  if (!author) {
    return null;
  }

  return (
    <div id="author" className={css.sectionAuthor}>
      <UserCard
        user={author}
        currentUser={currentUser}
        history={history}
        location={location}
        contactInfo={contactInfo}
        fetchContactInfoError={fetchContactInfoError}
        openContactModal={openContactModal}
      />
    </div>
  );
};

export default SectionAuthorMaybe;
